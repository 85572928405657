/* RadioButtonList.css */
/* 
.radio-button-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
  }
  
  .radio-button {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 13px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    color: var(--theme-color-input_text) !important;
  }
  
  .radio-button input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-radio {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .radio-button:hover input ~ .custom-radio {
    background-color: #ccc;
  }
  
  .radio-button input:checked ~ .custom-radio {
    background-color: #2196F3;
  }
  
  .custom-radio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .radio-button input:checked ~ .custom-radio:after {
    display: block;
  }
  
  .radio-button .custom-radio:after {
    top: 7px;
    left: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }
  
  @media(max-width: 767px) {
    .radio-button-list {
        flex-direction: column !important;
    }
  } */


/* CheckboxList.css */

/* CheckboxList.css */

.checkbox-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.checkbox:hover input~.custom-checkbox {
  background-color: #ccc;
}

.checkbox input:checked~.custom-checkbox {
  background-color: #2196F3;
}

.custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked~.custom-checkbox:after {
  display: block;
}

.checkbox .custom-checkbox:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@media(max-width: 767px) {
  .checkbox-list {
      flex-direction: column !important;
  }
}