/* Overlay for modal */
.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  /* Modal content */
  .video-modal-content {
    position: relative;
    width: 90%;
    max-width: 1000px;
    background: #ffffff;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    animation: slideUp 0.4s ease;
  }
  
  /* Video container */
  .video-container iframe {
    width: 100%;
    height: 500px;
    border: none;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: #ff4d4d;
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;
  }
  
  .close-button:hover {
    background: #ff1a1a;
  }
  
  /* Modal footer */
  .modal-footer {
    padding: 16px;
    text-align: center;
    background: #f9f9f9;
    border-top: 1px solid #e0e0e0;
  }
  
  /* Call-to-action button */
  .cta-button {
    padding: 12px 24px;
    background: #6198ff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .cta-button:hover {
    background: #4a7edc;
  }
  
  /* Open modal button */
  .open-modal-button {
    padding: 12px 24px;
    background: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .open-modal-button:hover {
    background: #45a045;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  