.elementor-52369 .elementor-element.elementor-element-35822992 {
    padding: 0px 70px 0px 70px;
}
.elementor-element {
    --widgets-spacing: 0px 0px;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-section {
    position: relative;
}
.body_style_fullscreen .post_content>.elementor .elementor-section.elementor-section-full_width:not(.elementor-inner-section)>.elementor-container.elementor-column-gap-no, .body_style_fullscreen [class*="type-cpt_"]>[class*="_page_content"]>.elementor .elementor-section.elementor-section-full_width:not(.elementor-inner-section)>.elementor-container.elementor-column-gap-no {
    margin-left: 15px;
    margin-right: 15px;
}
.elementor-section .elementor-container {
    display: flex
;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}
@media (min-width: 768px) {
    .elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
        width: 100%;
    }
}

.elementor-element {
    --widgets-spacing: 0px 0px;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex
;
}
.elementor-container>.elementor-row>.elementor-column>.elementor-element-populated, .elementor-container>.elementor-column>.elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
}
.elementor-container>.elementor-row>.elementor-column>.elementor-element-populated, .elementor-container>.elementor-column>.elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
}
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex
;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}
.elementor-52369 .elementor-element.elementor-element-6fafc72a {
    --spacer-size: 60px;
}
.elementor-widget:not(:last-child) {
    margin-bottom: 0;
}
.elementor-widget:not(:last-child) {
    margin-block-end: 0px;
}
.elementor-widget:not(:last-child) {
    /* margin-bottom: 20px; */
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-element .elementor-widget-container {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}
.sc_portfolio {
    position: relative;
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
;
    margin: 0 -10px;
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item {
    padding: 0 10px;
    flex: 0 0 1;
    width: 45%;
    transition: width 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    overflow: hidden;
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .sc_portfolio_item_inner {
    position: relative;
    height: 620px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.sc_portfolio_item * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .post_featured {
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transition: height 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    -ms-transition: height 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    transition: height 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .post_featured img {
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover !important;
    object-fit: cover !important;
    -webkit-transform: scale(1.06) translateZ(0) !important;
    -ms-transform: scale(1.06) translateZ(0) !important;
    transform: scale(1.06) translateZ(0) !important;
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.65, 0, 0.35, 1) !important;
    -ms-transition: -ms-transform 0.8s cubic-bezier(0.65, 0, 0.35, 1) !important;
    transition: transform 0.8s cubic-bezier(0.65, 0, 0.35, 1) !important;
    will-change: transform;
}
.post_featured.with_thumb img, .sc_layouts_blog_item_featured .post_featured.with_thumb img {
    position: relative;
    z-index: 2;
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active .post_featured img {
    -webkit-transform: scale(1) translateZ(0);
    -ms-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active .post_featured {
    height: 460px;
}

.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active {
    width: 100% !important;
    /* height: 460px; */
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item {
    padding: 0 10px;
    flex: 0 0 1;
    width: 45%;
    transition: width 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    overflow: hidden;
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active .post_featured {
        height: 155px !important;
    }
}

@media (max-width: 1023px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active .post_featured {
        height: 210px !important;
    }
}
@media (max-width: 1279px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active .post_featured {
        height: 290px !important;
    }
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .sc_portfolio_item_inner {
        height: 250px !important;
    }
}

@media (max-width: 1023px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .sc_portfolio_item_inner {
        height: 325px !important;
    }
}
@media (max-width: 1279px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .sc_portfolio_item_inner {
        height: 400px !important;
    }
}
.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .sc_portfolio_item_inner {
    position: relative;
    height: 620px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .is-active {
        width: 50% !important;
    }
}

.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item .is-active {
    width: 100% !important;
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item {
        width: 50% !important;
        display: inline-block;
        padding-bottom: 20px !important;
    }
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active {
        width: 50% !important;
    }
}

.sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item.is-active {
    width: 100% !important;
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_item {
        width: 50%;
        display: inline-block;
        padding-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .sc_portfolio.sc_portfolio_qw-case .sc_portfolio_content {
        display: block !important;
        line-height: 0;
        margin-bottom: -20px;
    }
}
@media (max-width: 767px) {
    .elementor-52369 .elementor-element.elementor-element-35822992 {
        padding: 0% 6% 0% 6% !important;
    }
}

@media (max-width: 1279px) {
    .elementor-52369 .elementor-element.elementor-element-35822992 {
        padding: 0px 30px 0px 30px !important;
    }
}