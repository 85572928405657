/* .aboutPadding { */
/* padding-top: 270px !important;
    padding-bottom: 0 !important;
}

@media (max-width: 1279px) {
    .aboutPadding {
        padding-top: 170px !important;
        padding-bottom: 0 !important;
    }

} */
.elementor-52483 .elementor-element.elementor-element-44c49adb>.elementor-widget-container {
    margin: 0px 0px 0px -328px;
    padding: 0% 40% 0% 0%;
}

.elementor-52479 .elementor-element.elementor-element-76a998d {
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    z-index: 1;
}

.elementor-52479 .elementor-element.elementor-element-432fc93 {
    z-index: 2;
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-50,
    .elementor-column[data-col="50"] {
        width: 50%;
    }
}


.sc_icons_accent2 .sc_icons_item {
    padding: 0 !important;
}

.sc_item_title_text_self {
    color: white

}

@media (min-width: 768px) {

    .elementor-column.elementor-col-33,
    .elementor-column[data-col="33"] {
        width: 50.333% !important;
    }
}

.mobilehead {
    display: none;
}

@media (max-width: 767px) {
    .aboutImgDivSelf {
        margin-bottom: 40px !important;
    }

    .elementor-3738 .elementor-element.elementor-element-3cc6356f>.elementor-widget-container {
        margin: 10px 0 0 0 !important;
    }

    .mobilehead {
        display: block;
    }

    .mobileheadtext{
        font-size: 17px !important;
    }

    .panelhead {
        display: none;
    }

}

.sc_item_title_texttitle {
  font-family: "Noto Sans", sans-serif !important;
}

.mobileheadtexttitle {
  font-family: "Noto Sans", sans-serif !important;
} 

@media (max-width: 767px) {
    .spaceinner {
        height: 15px !important;
    }
} 