.sc_team_item_self{
    transform: perspective(1500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1) !important;
    will-change: transform !important;
}

.justifycenter{
    justify-content: center !important;
}

.imgchnage {
    height: 350px;
    object-fit: cover;
}

.mrbspace{
    margin-bottom: 30px;
}