@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
}

.dashboard {
  display: flex;
  gap: 40px;
  padding: 40px;
  min-height: 100vh;
  background-color: #ffffff;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.left-section {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  background: linear-gradient(145deg, #ffffff, #f1f1f1);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-bottom: 8px;
}

.card-count {
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.card-1 {
  background: linear-gradient(145deg, #8e44ad, #9b59b6); /* Purple */
}

.card-2 {
  background: linear-gradient(145deg, #3498db, #2980b9); /* Blue */
}

.card-3 {
  background: linear-gradient(145deg, #2ecc71, #27ae60); /* Green */
}

.card-4 {
  background: linear-gradient(145deg, #f39c12, #e67e22); /* Orange */
}

.card:hover {
  transform: translateY(-6px);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
}

.right-section {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tabs {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.tab {
  padding: 8px 16px;
  background-color: #f7f7f7;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  color: #333;
  font-size: 14px;
}

.tab:hover {
  background-color: #f2f2f2;
  transform: scale(1.05);
}

.tab.active {
  background-color: #0c1c4c;
  color: white;
  font-weight: 700;
  transform: scale(1.05);
}

.tab-content {
  overflow-y: auto;
  max-height: 650px;
}

.tab-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tab-row {
  display: grid;
  grid-template-columns: 0.4fr 70px 3.5fr 2fr 1fr; /* Reduced width for first column, widened 4th column */
  align-items: center;
  gap: 15px;
  background: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tab-row:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.1);
}

.tab-cell {
  display: flex;
  text-align: left;
}

.sr-no {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.user-image-container {
  display: flex;
  justify-content: center;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.user-details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.user-details p {
  /* margin: -4px 0; */
  margin: 0;
  color: #777;
  font-size: 13px;
}
p{
    line-height: normal;
}
.label {
  font-weight: 600;
  color: #333;
}

.follow-up {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
}

.follow-up p {
  margin: 0;
  color: #777;
  font-size: 13px;
}

.remarks-button-container {
  display: flex;
  justify-content: center;
}


.remarks-button {
  padding: 8px 18px;
  background-color: #6198ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.remarks-button:hover {
  background-color: #4a7bff;
  transform: scale(1.05);
}

/* Make it responsive */
@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    padding: 20px;
  }

  .left-section, .right-section {
    width: 100%;
  }

  .tab-row {
    grid-template-columns: 0.4fr 60px 3fr 1.5fr 1fr; /* Adjusted layout for smaller screens */
  }

  .remarks-button {
    padding: 6px 14px;
    font-size: 12px;
  }

  .card-title, .card-count {
    font-size: 16px;
  }

  .user-details p {
    font-size: 12px;
  }

  .follow-up p {
    font-size: 12px;
  }
}