.App {
  text-align: center;
}

/* html {
  scroll-behavior: smooth;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rfm-marquee-container {
  overflow-x: clip !important;
  display: flex;
  flex-direction: row;
  position: relative;
  width: var(--width);
  transform: var(--transform);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* *{
  background-color: white;
  color: black;
} */
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.trx_addons_scroll_to_top.trx_addons_scroll_to_top.trx_addons_scroll_to_top {
  -webkit-transition: -webkit-transform 0.3s ease, bottom 0.3s ease, color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease, bottom 0.3s ease, color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease, bottom 0.3s ease, color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
}

.trx_addons_scroll_to_top.show {
  opacity: 1;
  bottom: 2em;
}

.scroll_to_top_style_default {
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--theme-color-inverse_hover);
  border-color: var(--theme-color-text_dark);
  background-color: var(--theme-color-text_dark);
}

.trx_addons_scroll_to_top {
  z-index: 7999;
}

.show {
  display: block !important;
}

.trx_addons_scroll_to_top {
  display: block;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  position: fixed;
  z-index: 100001;
  bottom: -4em;
  right: 2em;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
}


.react-datepicker__navigation--previous {
  display: none !important;
}

.react-datepicker__navigation--next {
  display: none !important;

}

.ant-picker-input input {
  margin: 10px 0px !important;
}

.css-dev-only-do-not-override-1kf000u {
  background-color: transparent !important;
  border: #161515 !important;
  color: white !important;
}

#EventStartTime {
  background-color: transparent !important;
  margin: 12px 0px !important;
  border: #161515 !important;
  color: white !important;
}

.ant-picker {
  color: white !important;
}

.ant-btn span {
  background-color: #00aaff !important;
  padding: 0px 7px !important;
  border-radius: 5px !important
}

.sc_button_simple :hover {
  cursor: pointer !important;
}

.ant-picker-input input::placeholder {
  color: #abaaaa !important;
  /* font-weight: 600 !important; */
  font-size: 1.1em !important;
  /* Set your desired color */

}

.ant-select-selection-placeholder {
  color: #abaaaa !important;
  /* font-weight: 600 !important; */
  font-size: 1.1em;
  /* Set your desired color */

}

.wpcf7-form-control-wrap textarea::placeholder {
  font-size: 0.9em !important;
}

.sc_item_title_text {
  line-height: normal !important;
  font-size: 0.9em !important;
}

.mainApplayout1 {
  padding: 50px 0 !important;
}

:where(.css-77tu7h).ant-picker-outlined,
:where(.css-dev-only-do-not-override-77tu7h).ant-picker-outlined {
  background: none !important;
  border: none !important;
}

.css-77tu7h,
:where(.css-dev-only-do-not-override-77tu7h).ant-btn {
  border: none !important;
}

.mobilebanner {
  display: none !important;
}

.text-justify {
  text-align: justify !important;
}


@media (max-width: 767px) {
  .mobilebanner {
    display: flex !important;
  }

  .desktopbanner {
    display: none !important;
  }

  .footerCol2 {
    padding: 0px 20px !important;
  }

  .mobilemap {
    width: 100% !important;
  }
}

div.wpcf7 .form-style-1 textarea {
  /* min-height: 3rem;  */
  height: 1.5rem;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}


:where(.css-dev-only-do-not-override-161f05s).ant-picker-outlined {
  background: none;
  border-width: 0px;
  border-color: none;
}


:where(.css-dev-only-do-not-override-161f05s).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-161f05s).ant-picker-outlined:focus-within {
  border-color: none;
  box-shadow: none;
  outline: 0;
  background: none;
}

:where(.css-dev-only-do-not-override-161f05s).ant-picker-outlined:hover {
  border-color: none;
  background: none;
}

:where(.css-dev-only-do-not-override-161f05s).ant-btn-variant-solid {
  background: none !important;
  border: none !important;
}

:where(.css-dev-only-do-not-override-161f05s).ant-btn-color-primary {
  box-shadow: none !important;
}

/* //krishna 10-01-2025 */
@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 35% !important;
  }
}


:where(.css-dev-only-do-not-override-1htoz2s).ant-picker-outlined:hover {
  border-color: white;
  background-color: none !important;
}

:where(.css-dev-only-do-not-override-1htoz2s).ant-picker-outlined {
  background: none !important;
  border-width: none;
  border-style: none;
  border-color: none;
}

.pointerevent {
  pointer-events: none !important;
}


:where(.css-dev-only-do-not-override-1htoz2s).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-1htoz2s).ant-picker-outlined:focus-within {
  border-color: #1677ff;
  box-shadow: none;
  outline: 0;
  background-color: #ffffff;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1htoz2s)
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) 
.ant-select-selector{
  box-shadow: none!important;
}
.ant-picker-outlined{
  border: unset;
 background: transparent !important;
}