.entertainingimgcss {
    margin: 0px 50px;
}

@media (max-width: 767px) {
    .entertainingself {
        margin: 10px 10px 0 10px !important;
    }

    .entertainingimgcss {
        margin: 0px 10px !important
    }
}