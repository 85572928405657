.homeBanerMainSection {
    padding: 0 0 !important;
}

.BannerText {
    display: flex;
    flex-wrap: wrap;
}

.bannerLineText {
    margin-bottom: 35px;
    text-align: justify;
}

/* .bannerLineTopText { */
    /* margin-bottom: 30px; */
/* } */

/* .completeBanner{
    padding: 50px 90px 180px 70px !important;
} */
.completeBanner {
    padding: 50px 90px 0px 70px !important;
    /* position: absolute;
    top: 50px;
    left: 50px; */
}

/* .readMoreText{
    justify-content: flex-start !important ;
} */

.readMoreText {
    /* display: inline-block; */
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.6s forwards;
    /* letter-spacing: 0.1px; */
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 767px) {
    .completeBanner {
        padding: 10px 0px 0px 0px !important;
        margin-bottom: 30px;
    }

    .elementor-52477 .elementor-element.elementor-element-65a4fd55>.elementor-element-populated {
        margin: 0% 0% 0% 0% !important;
        --e-column-margin-right: 0%;
        --e-column-margin-left: 9.5%;
    }

    .bannerTitle {
        margin-right: auto !important;
    }

    .mobilescroll {
        display: none !important;
    }
}

