@media (min-width: 768px) {
    .footerCol1 {
        width: 33.4%;
    }

    .footerCol2 {
        width: 25%;
    }

    .footerCol3 {
        width: 25.3%;
    }

    .footerCol4 {
        width: 16.3%;
    }
}

@media (max-width: 767px) {
    .footerCol1 {
        width: 50%;

    }

    .footerCol2 {
        width: 50%;
    }

    .footerCol3 {
        width: 50%;
    }

    .footerCol4 {
        width: 50%;
    }
}

@media (max-width: 450px) {
    .footerCol1 {
        width: 100%;
    }

    .footerCol2 {
        width: 100%;
    }

    .footerCol3 {
        width: 50%;
    }

    .footerCol4 {
        width: 50%;
    }
}

.footerCol1,
.footerCol2,
.footerCol3,
.footerCol4 {
    margin-bottom: 20px;
}

.custom_links_list_item_title {
    color: #ABAAAA !important;
}

.footerCopyright {
    max-width: 1320px !important;
}

.footer_wrap_self {
    background-color: #161515 !important;
    /* color: #ABAAA !important */
}

.elementor_section_self {
    padding-top: 100px !important;
}

.map_parent_div {
    text-align: left;
}

@media (max-width: 768px) {
    .elementor_section_self {
        padding-top: 50px !important;
    }

    .map_parent_div {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important
    }
}

.elementor-container-self {
    display: flex !important;
    margin-right: auto !important;
    margin-left: auto !important;
    position: relative !important;
}

.costome_link_list_self {
    padding-left: 0px !important;
    color: #ABAAAA !important;
}

.mobilemap {
    width: 80% !important;
}


.footerCol2{
    padding: 0px 20px !important;
}

@media (max-width: 767px) {
    .mobilemap {
        width: 100% !important;
    }

    .footerCol2{
        padding: 0px !important;
    }
}

/* .underlinetitle{
    text-decoration: underline;
} */