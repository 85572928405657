.terms-container {
  padding: 20px;
  /* background: #f9f9f9; */
}

.terms-container h3 {
  margin: 0 0 10px;
  color: #333;
}

.terms-container p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.terms-container ul {
  margin: 0;
  padding: 0 0 0 20px;
  list-style-type: disc;
  font-size: 14px;
  line-height: 1.6;
}

.terms-container a {
  color: #6198ff !important;
  text-decoration: none!important;
}

.terms-container a:hover {
  text-decoration: underline!important;
}



.ant-space {
  width: 100% !important;
}
.ant-picker ,.wpcf7-form-control {
  width: 100%;
}
.ant-select-selector{
  background: unset !important;
  border: unset !important;
  /* padding: 60px; */
  margin-left: 10px !important;
}
.ant-select-dropdown {
  width: 80px !important;
  /* text-align: center !important; */
}
/* .ant-select-focused{
  all: unset !important;
} */
/* .ant-select-outlined{
  all: unset !important;
} */
 .ant-select{
  margin:  10px !important;
 }