.mindfulllself {
    padding: 0px 60px !important;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .mindfulllself {
        /* padding: 0px 45px !important; */
        padding: 0px 10px !important;
        margin-bottom: 15px;
    }

    .mindfullimgcss {
        padding: 0px 10px !important;
    }

    .spaceinner {
        height: 15px !important;
    }
}
