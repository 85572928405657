@media (min-width: 768px) {
    .aboutImageParent {
        margin-bottom: 15px !important;
    }
}

@media (max-width: 768px) {
    .home-about-text-content {
        margin: 10px auto !important;
    }

    .elementor-element-4295be78 {
        padding: 0px 20px 0px 20px !important;
    }

    .aboutImageParent {
        margin-bottom: 15px !important;
    }


    .aboutmargin {
        margin-top: 15px !important;
        font-size: 1.3rem;
    }

    .HomeAbout {
        line-height: 1.2em !important;
    }
}

.aboutmargin {
    margin-top: 15px !important;
    font-size: 1.3rem;
    letter-spacing: 0.1px !important;
}

.sc_item_title_text4 {
    font-size: 0.7em !important;
    letter-spacing: 0.1px !important;
}