@media (max-width: 767px) {
    .elementor-58276 .elementor-element.elementor-element-136436d {
        padding: 10px 20px 10px 20px;
    }
}

.elementor-58276 .elementor-element.elementor-element-136436d {
    padding: 15px 30px 15px 30px;
}

@media (max-width: 767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px;
    }
}

@media (max-width: 1279px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px;
    }
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1320px;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

@media (max-width: 1279px) {
    .elementor-section .elementor-container {
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .elementor-58276 .elementor-element.elementor-element-1e7cdd9 {
        width: 50%;
    }

    .elementor-58276 .elementor-element.elementor-element-36e49d9 {
        width: 50%;
    }

    .subtitlecss {
       font-size: 13px !important;
    }
}

.elementor-58276 .elementor-element.elementor-element-136436d>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.sf-with-ul-self {
    display: none !important;
}

.sc_layouts_menu_nav_self {
    touch-action: pan-y !important;
}

.menu_mobile_overlay_self {
    display: none;
}

.topHeaderPanelSelf {
    position: sticky !important;
    top: 0 !important;
    /* Stick to the top of the viewport */
    z-index: 1000 !important;
    /* Ensure it appears above other content */
    background-color: #fff !important;
    /* Match your theme design */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    /* Optional: Add subtle shadow for a polished look */
}

.subtitlecss {
    text-align: right !important;
    margin: 0 !important;
}