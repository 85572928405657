.viewMoreParent {
    text-align: end;
}

/* .swiper-wrapper {
    justify-content: center !important;
} */

.justifycenter {
    justify-content: center !important;
}

.m-0 {
    margin-bottom: 0px !important;
}

/* Row */
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

/* Column for medium screens and up (col-md-4) */
.col-md-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.bannerLineTopText_2 {
    margin-bottom: 35px !important;
    font-size: 2.3rem !important;
}


@media (min-width: 768px) {
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (max-width: 767px) {

    .row {
        display: inline-block;
        flex-wrap: wrap;
        margin-right: 0;
        margin-left: 0;
    }
}

/* .ourteamrow {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

.ourteamcolmd {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
} */

/* .ourteamtitle {
    font-size: 3.353em !important;
    color: var(--theme-color-text_dark) !important;
} */

.imgchnage {
    height: 350px;
    object-fit: cover;
}