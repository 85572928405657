.elementor-52477 .elementor-element.elementor-element-65a4fd55.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: flex-end;
    align-items: flex-end;
}

.elementor-52477 .elementor-element.elementor-element-65a4fd55>.elementor-element-populated {
    margin: 0% 0% 0% 9.5%;
    --e-column-margin-right: 0%;
    --e-column-margin-left: 9.5%;
}

.elementor-container>.elementor-row>.elementor-column>.elementor-element-populated,
.elementor-container>.elementor-column>.elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
}

/* .elementor-column-gap-extended>.elementor-row>.elementor-column>.elementor-element-populated, .elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: calc(var(--theme-var-elm_gap_extended) / 2);
} */

/* .elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: 15px;
} */
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}

.elementor *,
.elementor :after,
.elementor :before {
    box-sizing: border-box;
}

.elementor-52477 .elementor-element.elementor-element-2687c155 {
    --spacer-size: 7vw;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 0;
}

.elementor-widget:not(:last-child) {
    margin-block-end: 0px;
}

.elementor-widget-wrap>.elementor-element {
    width: 100%;
}

.elementor-element {
    --widgets-spacing: 0px 0px;
}

.elementor-52477 .elementor-element.elementor-element-424b3520 {
    width: var(--container-widget-width, 400px);
    max-width: 400px;
    --container-widget-width: 400px;
    --container-widget-flex-grow: 0;
}

.elementor-widget-wrap .elementor-element.elementor-widget__width-auto,
.elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
    max-width: 100%;
}

.elementor-element,
.elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}

.elementor-widget {
    position: relative;
}


.elementor-52477 .elementor-element.elementor-element-1b8b3008.elementor-column>.elementor-widget-wrap {
    /* justify-content: flex-end; */
}

.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding: 0;
}

.elementor-52477 .elementor-element.elementor-element-777a51c {
    width: var(--container-widget-width, 535px);
    max-width: 535px;
    --container-widget-width: 535px;
    --container-widget-flex-grow: 0;
}

.elementor-widget:not(:last-child).elementor-absolute,
.elementor-widget:not(:last-child).elementor-widget__width-auto,
.elementor-widget:not(:last-child).elementor-widget__width-initial {
    margin-bottom: 0;
}



.elementor-52477 .elementor-element.elementor-element-4295be78 {
    padding: 0px 70px 0px 70px;
}

.elementor-section {
    position: relative;
}

.sc_item_descr {
    color: #ABAAAA;
    font-family: "DM Sans", sans-serif
}


.elementor-element .elementor-widget-container {
    color: #ABAAAA;
    font-family: "DM Sans", sans-serif;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

/* -------------------- */
/* .elementor-52477 .elementor-element.elementor-element-3e6909d:not(.elementor-motion-effects-element-type-background), .elementor-52477 .elementor-element.elementor-element-3e6909d > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-image: url("../../Assets/VideoHome.jpg");
    background-position: 100% 20%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 571.94px !important;
    width: 1585px;
} */
/* @media (max-width: 768px) {
    .elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
        background-image: url("../../Assets/VideoHome.jpg");
        background-position: 50% 20%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 571.94px !important;
        width: 1585px;
    }
} */
.elementor-52477 .elementor-element.elementor-element-3e6909d {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-52477 .elementor-element.elementor-element-05bbf5a {
    --spacer-size: 15.4vw;
}

.elementor-element-b14668d {
    --spacer-size: 15.4vw;

}

.eventicity-fadeinup {
    -webkit-animation-name: eventicity-fadeinup;
    animation-name: eventicity-fadeinup;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
}

.animated {
    -webkit-animation-duration: 750ms;
    animation-duration: 750ms;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
}

.animated {
    animation-duration: 1.25s;
}

.sc_layouts_column .vc_separator,
.sc_layouts_column .vc_empty_space,
.sc_layouts_column .elementor-widget-divider,
.sc_layouts_column .elementor-widget-spacer {
    width: 100%;
}


article,
aside,
details,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

.elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
}





.elementor-section.elementor-section-boxed:not(.elementor-section-with-custom-width)>.elementor-column-gap-extended {
    max-width: calc(var(--theme-var-page) + var(--theme-var-elm_gap_extended));
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1320px;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
}





.elementor-52477 .elementor-element.elementor-element-77e34a6 {
    --spacer-size: 7vw;
}




.elementor-52477 .elementor-element.elementor-element-af0cc35 .trx_addons_bg_text_char {
    color: #F25A2A !important;
    font-family: "Marcellus", Sans-serif !important;
    font-size: 130px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    line-height: 0.9em !important;
    letter-spacing: 0px !important
}

.widget_instagram_images_item_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    width: 16.666%;
    height: auto;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-66,
    .elementor-column[data-col="66"] {
        width: 100%;
    }
}



@media (min-width: 768px) {

    .elementor-column.elementor-col-100,
    .elementor-column[data-col="100"] {
        width: 100%;
    }
}




.elementor-52477 .elementor-element.elementor-element-0f4cde1 {
    --spacer-size: 48px;
}

.scheme_orange_dark,
body.scheme_orange_dark {
    --theme-color-bg_color: #161515;
    --theme-color-bd_color: #393939;
    --theme-color-text: #ABAAAA;
    --theme-color-text_light: #8B8582;
    --theme-color-text_dark: #FCFCFC;
    --theme-color-text_link: #F25A2A;
    --theme-color-text_hover: #E24615;
    --theme-color-text_link2: #2A66F2;
    --theme-color-text_hover2: #0E4CDE;
    --theme-color-text_link3: #F22A4C;
    --theme-color-text_hover3: #D9092C;
    --theme-color-alter_bg_color: #2E2D2D;
    --theme-color-alter_bg_hover: #262525;
    --theme-color-alter_bd_color: #393939;
    --theme-color-alter_bd_hover: #4A4A4A;
    --theme-color-alter_text: #ABAAAA;
    --theme-color-alter_light: #8B8582;
    --theme-color-alter_dark: #FCFCFC;
    --theme-color-alter_link: #F25A2A;
    --theme-color-alter_hover: #E24615;
    --theme-color-alter_link2: #2A66F2;
    --theme-color-alter_hover2: #0E4CDE;
    --theme-color-alter_link3: #F22A4C;
    --theme-color-alter_hover3: #D9092C;
    --theme-color-extra_bg_color: #3E1717;
    --theme-color-extra_bg_hover: #3f3d47;
    --theme-color-extra_bd_color: #672C2C;
    --theme-color-extra_bd_hover: #773E3E;
    --theme-color-extra_text: #ABAAAA;
    --theme-color-extra_light: #8B8582;
    --theme-color-extra_dark: #FCFCFC;
    --theme-color-extra_link: #F25A2A;
    --theme-color-extra_hover: #FCFCFC;
    --theme-color-extra_link2: #80d572;
    --theme-color-extra_hover2: #8be77c;
    --theme-color-extra_link3: #ddb837;
    --theme-color-extra_hover3: #eec432;
    --theme-color-input_bg_color: #transparent;
    --theme-color-input_bg_hover: #transparent;
    --theme-color-input_bd_color: #353B4B;
    --theme-color-input_bd_hover: #4A4A4A;
    --theme-color-input_text: #ABAAAA;
    --theme-color-input_light: #ABAAAA;
    --theme-color-input_dark: #FCFCFC;
    --theme-color-inverse_bd_color: #FCFCFC;
    --theme-color-inverse_bd_hover: #2E2D2D;
    --theme-color-inverse_text: #F9F9F9;
    --theme-color-inverse_light: #6f6f6f;
    --theme-color-inverse_dark: #392B25;
    --theme-color-inverse_link: #FCFCFC;
    --theme-color-inverse_hover: #392B25;
    --theme-color-bg_color_0: rgba(22, 21, 21, 0);
    --theme-color-bg_color_02: rgba(22, 21, 21, 0.2);
    --theme-color-bg_color_07: rgba(22, 21, 21, 0.7);
    --theme-color-bg_color_08: rgba(22, 21, 21, 0.8);
    --theme-color-bg_color_09: rgba(22, 21, 21, 0.9);
    --theme-color-alter_bg_color_07: rgba(46, 45, 45, 0.7);
    --theme-color-alter_bg_color_08: rgba(46, 45, 45, 0.8);
    --theme-color-alter_bg_color_04: rgba(46, 45, 45, 0.4);
    --theme-color-alter_bg_color_00: rgba(46, 45, 45, 0);
    --theme-color-alter_bg_color_02: rgba(46, 45, 45, 0.2);
    --theme-color-alter_bd_color_02: rgba(57, 57, 57, 0.2);
    --theme-color-alter_dark_015: rgba(252, 252, 252, 0.15);
    --theme-color-alter_dark_02: rgba(252, 252, 252, 0.2);
    --theme-color-alter_dark_05: rgba(252, 252, 252, 0.5);
    --theme-color-alter_dark_08: rgba(252, 252, 252, 0.8);
    --theme-color-alter_link_02: rgba(242, 90, 42, 0.2);
    --theme-color-alter_link_07: rgba(242, 90, 42, 0.7);
    --theme-color-extra_bg_color_05: rgba(62, 23, 23, 0.5);
    --theme-color-extra_bg_color_07: rgba(62, 23, 23, 0.7);
    --theme-color-extra_link_02: rgba(242, 90, 42, 0.2);
    --theme-color-extra_link_07: rgba(242, 90, 42, 0.7);
    --theme-color-text_dark_003: rgba(252, 252, 252, 0.03);
    --theme-color-text_dark_005: rgba(252, 252, 252, 0.05);
    --theme-color-text_dark_008: rgba(252, 252, 252, 0.08);
    --theme-color-text_dark_015: rgba(252, 252, 252, 0.15);
    --theme-color-text_dark_02: rgba(252, 252, 252, 0.2);
    --theme-color-text_dark_03: rgba(252, 252, 252, 0.3);
    --theme-color-text_dark_05: rgba(252, 252, 252, 0.5);
    --theme-color-text_dark_07: rgba(252, 252, 252, 0.7);
    --theme-color-text_dark_08: rgba(252, 252, 252, 0.8);
    --theme-color-text_link_007: rgba(242, 90, 42, 0.07);
    --theme-color-text_link_02: rgba(242, 90, 42, 0.2);
    --theme-color-text_link_03: rgba(242, 90, 42, 0.3);
    --theme-color-text_link_04: rgba(242, 90, 42, 0.4);
    --theme-color-text_link_07: rgba(242, 90, 42, 0.7);
    --theme-color-text_link2_08: rgba(42, 102, 242, 0.8);
    --theme-color-text_link2_007: rgba(42, 102, 242, 0.07);
    --theme-color-text_link2_02: rgba(42, 102, 242, 0.2);
    --theme-color-text_link2_03: rgba(42, 102, 242, 0.3);
    --theme-color-text_link2_05: rgba(42, 102, 242, 0.5);
    --theme-color-text_link3_007: rgba(242, 42, 76, 0.07);
    --theme-color-text_link3_02: rgba(242, 42, 76, 0.2);
    --theme-color-text_link3_03: rgba(242, 42, 76, 0.3);
    --theme-color-inverse_text_03: rgba(249, 249, 249, 0.3);
    --theme-color-inverse_link_08: rgba(252, 252, 252, 0.8);
    --theme-color-inverse_hover_08: rgba(57, 43, 37, 0.8);
    --theme-color-text_dark_blend: #ffffff;
    --theme-color-text_link_blend: #ff6d38;
    --theme-color-alter_link_blend: #ff6d38;
}

@media (min-width: 768px) {
    .elementor-52477 .elementor-element.elementor-element-124a114 {
        width: 50%;
    }
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-33,
    .elementor-column[data-col="33"] {
        width: 33.333%;
    }
}

@media (min-width: 768px) {
    .elementor-52477 .elementor-element.elementor-element-965735a {
        width: 16.332%;
    }
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-33,
    .elementor-column[data-col="33"] {
        width: 33.333%;
    }
}

.elementor-52477 .elementor-element.elementor-element-1cf35d8 {
    --spacer-size: 52px;
}

.sc_team {
    position: relative;
}

.slider_outer {
    position: relative;
}

.slider_container:not(.slider_height_fixed) .slider-wrapper {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

[data-mouse-helper-hide-cursor="1"],
[data-mouse-helper-hide-cursor="1"] * {
    cursor: none !important;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0px, 0, 0);
}

.slider_container:not(.slider_height_fixed) .slider-slide {
    height: auto;
}

.slider_container .slider-slide {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}

.sc_team_short .sc_team_slider .sc_team_item {
    padding-bottom: 4px;
}

.sc_team_slider .sc_team_item {
    margin-bottom: 0 !important;
}

.sc_team_slider .sc_team_item,
.sc_team_columns_wrap .sc_team_item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}





.post_featured:not(.post_featured_bg)[class*="hover_"] {
    display: inline-block;
    vertical-align: top;
}

.sc_team .sc_team_item_thumb[class*="hover_"] {
    display: block;
    margin-bottom: 0;
}

.sc_team_short .sc_team_item_thumb {
    border: none;
}

.post_featured[class*="hover_"],
.post_featured[class*="hover_"] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.post_featured[class*="hover_"] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.sc_team_short .sc_team_item_thumb {
    border-bottom: 5px solid #efa758;
}

.trx_addons_hover,
.trx_addons_hover>* {
    color: var(--theme-color-text_hover);
}

.post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
}

.trx_addons_hover {
    color: #b88144;
}

.trx_addons_hover {
    position: relative;
    overflow: hidden;
}

.sc_team_short .post_featured img {
    -webkit-transition: -webkit-transform 0.3s ease;
    -ms-transition: -ms-transform 0.3s ease;
    transition: transform 0.3s ease;
}

.trx_addons_hover img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}



img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

/* img[Attributes Style] {
    width: 570px;
    aspect-ratio: auto 570 / 696;
    height: 696px;
} */
/* user agent stylesheet */
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

.sc_team_short .trx_addons_hover_team {
    padding: 15px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    z-index: 99;
}



.sc_team .sc_team_item_thumb .sc_team_item_link {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.post_featured .post_link {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.elementor a {
    box-shadow: none;
    text-decoration: none;
}



a,
button,
input[type="button"],
input[type="submit"] {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a,
a:hover,
:focus,
a:focus,
:active,
a:active {
    outline: 0;
}

a {
    text-decoration: none;
    background: transparent;
    color: var(--theme-color-text_link);
}

.sc_team_short .sc_team_item_thumb+.sc_team_item_info {
    margin-top: 24px;
}

.sc_team_short .sc_team_item_thumb+.sc_team_item_info {
    margin-top: 1em;
}

.sc_team_short .sc_team_item_title {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2em;
    color: #fcfcfc;
    text-align: center;
}

.sc_team .sc_team_item_title {
    margin: 1;
}

.sc_team_short .sc_team_item_subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    margin-top: 11px;
    color: var(--theme-color-alter_text);
}

.sc_team .sc_team_item_subtitle {
    font-size: 13px;
    line-height: 20px;
    font-style: italic;
    letter-spacing: 0;
    margin-top: 1px;
}

.mySwiper {
    display: flex;
}

.custom-logo-link img,
.sc_layouts_logo img {
    max-height: 6em;
    width: revert-layer;
    vertical-align: middle;
}

.elementor-58276 .elementor-element.elementor-element-e883e88 {
    padding: 0px 55px 0px 55px;
    background-color: #161515;
}

.sc_layouts_row_type_compact {
    padding: 0.75em 0;
}

h1.sc_item_title2 {
    line-height: 0.7em;
}

.sc_layouts_row {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.sc_item_title_text2 {
    font-size: 0.6em;
    letter-spacing: 0.1px;
    /* word-wrap: normal */
}

.sc_item_title_text1 {
    font-size: 1.5em;
    word-wrap: normal
}

.elementor-widget-wrap {
    text-align: start;
}

.homeMainTitle {
    text-align: center;
}

.elementor-3738 .elementor-element.elementor-element-0af37fb {
    --spacer-size: 4.3vw;
}

.elementor-3738 .elementor-element.elementor-element-f5e1a0 {
    --spacer-size: 50px;
}

.elementor-3738 .elementor-element.elementor-element-3cc6356f>.elementor-widget-container {
    margin: 0% 0% 10% 0%;
}

.aboutImgDiv {
    text-align: end;
}

.elementor-3738 .elementor-element.elementor-element-3cc6356f>.elementor-widget-container {
    margin: 3% 0% 0% 0%;
}

.elementor-icon-wrapper {
    text-align: center;
}

.menu_hover_zoom_line .sc_layouts_menu_nav>li:not(.menu-collapse)>a:after {
    background-color: #FCFCFC;
}

.menu_hover_zoom_line>ul>li:not(.menu-collapse)>a:after {
    content: "" !important;
    display: block !important;
    position: relative;
    z-index: 1;
    top: auto;
    bottom: -2px;
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    width: 0;
    height: 1px;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.swiper-wrapper {
    max-height: 480px !important;

}

.page_wrap.with_bg .page_content_wrap {
    background-color: transparent !important;
}

.body_style_fullscreen .page_content_wrap {
    padding: 0;
    position: relative;
}

.remove_margins .page_content_wrap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.page_content_wrap {
    padding-top: 3.35rem;
}

.page_content_wrap {
    position: relative;
}

#page_preloader,
.page_content_wrap,
.custom-background .content_wrap>.content,
.background_banner_wrap~.content_wrap>.content {
    background-color: #161515;
}

.page_content_wrap {
    padding-top: 7.1rem;
    padding-bottom: 7.4rem;
}

.page_wrap.with_bg {
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    background-size: cover;
}

.header_position_over .page_wrap {
    position: relative;
}

/* .eventicity_inline_1335811943 {
    background-image: url(https://eventicity.themerex.net/wp-content/uploads/2024/04/new-h7-page-bg.svg);
} */
.page_wrap,
.content_wrap {
    margin: 0 auto;
}

.page_wrap {
    min-height: 100vh;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.eventicity_inline_1100695198 {
    background-image: url("../../Assets/BgHome.png");
    background-color: #161515;
}

.elementor-52477 .elementor-element.elementor-element-6897110 {
    width: auto;
    max-width: auto;
}

.elementor-52477 .elementor-element.elementor-element-6897110>.elementor-widget-container {
    padding: 12px 12px 12px 12px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #FFFFFF1C;
    border-radius: 100% 100% 100% 100%;
}

.elementor-52477 .elementor-element.elementor-element-6897110 .elementor-icon-wrapper {
    text-align: center;
}

.play_center .elementor-icon-wrapper {
    line-height: 0;
}

.elementor-icon-wrapper {
    line-height: 1;
}

.elementor-52477 .elementor-element.elementor-element-6897110.elementor-view-stacked .elementor-icon {
    background-color: #FFFFFF;
    color: #1F242E;
}

.elementor-52477 .elementor-element.elementor-element-6897110 .elementor-icon {
    font-size: 15px;
    padding: 25px;
    border-radius: 100% 100% 100% 100%;
}

.elementor-shape-circle .elementor-icon {
    border-radius: 50%;
}

.elementor-view-stacked .elementor-icon {
    padding: .5em;
    background-color: #69727d;
    color: #fff;
    fill: #fff;
}

.elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all .3s;
    color: #69727d;
    font-size: 50px;
    text-align: center;
}

.elementor-52477 .elementor-element.elementor-element-6897110.elementor-view-stacked .elementor-icon svg {
    fill: #1F242E;
}

.elementor-52477 .elementor-element.elementor-element-6897110 .elementor-icon svg {
    height: 15px;
}

.play_center .elementor-icon i,
.play_center .elementor-icon svg {
    left: 2px;
}

svg:not(:root) {
    overflow: hidden;
}

.elementor-icon i,
.elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}

@media (max-width: 1279px) {

    .elementor-52477 .elementor-element.elementor-element-3e6909d:not(.elementor-motion-effects-element-type-background),
    .elementor-52477 .elementor-element.elementor-element-3e6909d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: top center;
    }
}

.elementor-52477 .elementor-element.elementor-element-3e6909d:not(.elementor-motion-effects-element-type-background),
.elementor-52477 .elementor-element.elementor-element-3e6909d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../Assets/VideoHome.jpg");

    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: cover;
}

.elementor-52477 .elementor-element.elementor-element-3e6909d {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6 {
    width: auto;
    max-width: auto;
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6>.elementor-widget-container {
    padding: 10px 10px 10px 10px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #FFFFFF1C;
    border-radius: 100% 100% 100% 100%;
}

.elementor-element .elementor-widget-container {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6 .elementor-icon-wrapper {
    text-align: center;
}

.play_center .elementor-icon-wrapper {
    line-height: 0;
}

.elementor-icon-wrapper {
    line-height: 1;
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6.elementor-view-stacked .elementor-icon {
    background-color: #FFFFFF;
    color: #1F242E;
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6 .elementor-icon {
    font-size: 12px;
    padding: 20px;
    border-radius: 100% 100% 100% 100%;
}

.elementor-shape-circle .elementor-icon {
    border-radius: 50%;
}

.elementor-view-stacked .elementor-icon {
    padding: .5em;
    background-color: #69727d;
    color: #fff;
    fill: #fff;
}

.elementor a {
    box-shadow: none;
    text-decoration: none;
}

.elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all .3s;
    color: #69727d;
    font-size: 50px;
    text-align: center;
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6.elementor-view-stacked .elementor-icon svg {
    fill: #1F242E;
}

.elementor-52477 .elementor-element.elementor-element-dc6efd6 .elementor-icon svg {
    height: 12px;
}

.play_center .elementor-icon i,
.play_center .elementor-icon svg {
    left: 2px;
}

svg:not(:root) {
    overflow: hidden;
}

.elementor-icon i,
.elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}

.elementor-4614 .elementor-element.elementor-element-6735ad1d>.elementor-widget-container {
    margin: -0.3em 0em -1.5em 0em;
}

.elementor-element .elementor-widget-container {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}

.elementor-4614 .elementor-element.elementor-element-6735ad1d .elementor-icon-box-wrapper {
    text-align: center;
}

@media (max-width: 767px) {
    .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        flex-direction: unset;
    }
}

.elementor-widget-icon-box.elementor-position-top .elementor-icon-box-wrapper {
    display: block;
    text-align: center;
    flex-direction: unset;
}

.elementor-widget-icon-box .elementor-icon-box-wrapper {
    display: block;
    text-align: center;
}

@media (max-width: 767px) {
    .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto;
    }
}

.elementor-widget-icon-box.elementor-position-top .elementor-icon-box-icon {
    margin-bottom: var(--icon-box-icon-margin, 15px);
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 767px) {
    .elementor-4614 .elementor-element.elementor-element-6735ad1d .elementor-icon {
        font-size: 12px;
    }
}

@media (max-width: 1279px) {
    .elementor-4614 .elementor-element.elementor-element-6735ad1d .elementor-icon {
        font-size: 14px;
    }

    .elementor-icon {
        font-size: 19px !important;
    }
}

/* .elementor-4614 .elementor-element.elementor-element-6735ad1d .elementor-icon {
    font-size: 19px;
} */
.elementor-widget-icon-box.elementor-view-default .elementor-icon {
    fill: var(--theme-color-text_dark);
    color: var(--theme-color-text_dark);
}

@media (max-width: 621px) {
    .wpcf7-submit-style {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 767px) {
    h1.sc_item_title2 {
        line-height: 0.6em;
    }

    .sc_layouts_row {
        -webkit-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }

    .sc_item_title_text2 {
        font-size: 0.5em;
        letter-spacing: 0.1px;
        /* word-wrap: normal */
    }
}