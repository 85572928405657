.body_style_fullscreen .page_content_wrap {
    padding: 0;
    position: relative;
}

.contactPadding {
    padding-top: 270px !important;
    padding-bottom: 0 !important;
}

.contact_details_main {
    margin-bottom: "50px" !important;
    text-align: start !important;
}
.social_icon_facebook-1 .icon-facebook-1 {
    color: #3b5998; /* Facebook Blue */
    background-color: transparent; /* No background initially */
    border-radius: 50%; /* To make the background circular around the icon */
    padding: 10px; /* Adjust the padding to make background appear around the icon */
}

.social_icon_youtube .icon-youtube {
    color: #FF0000; /* YouTube Red */
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
}

.social_icon_instagram .icon-instagram {
    color: #E4405F; /* Instagram Pink */
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
}

.social_icon_google .icon-google {
    color: #db4437; /* Google Red */
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
}

.social_icon_whatsapp .icon-whatsapp {
    color: #25D366; /* WhatsApp Green */
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
}

/* Hover effects for social media icons */
.social_icon{
    background-color: white !important;
}
/* Optional: Add transition effect for smooth hover change */
.social_icon .icon-facebook-1,
.social_icon .icon-youtube,
.social_icon .icon-instagram,
.social_icon .icon-google,
.social_icon .icon-whatsapp {
    transition: all 0.3s ease;
    font-size: larger;
}
/* .sc_iconitem-self { */
    /* margin-bottom: 40px !important; */
/* } */

.sc_social_self {
    text-align: start;
}

.input_div_self {
    display: none !important;
}

/* div.wpcf7 .form-style-1 .style-line.icon-name:before {
    content: '\e9ae';
}
div.wpcf7 .form-style-1 .style-line[class*="icon-"]:before {
    color: #A5A5A5;
    display: block;
    font-family: "fontello";
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
} */
div.wpcf7 .form-style-1 .style-line[class*="icon-"]:before {
    color: var(--theme-color-input_text);
    display: block;
    font-family: "fontello";
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
}

.link_text {
    font-family: "DM Sans", sans-serif !important;
}

.link_text a {
    color: var(--theme-color-text) !important;
}

.mapwidth {
    width: 100%;
}

@media (max-width: 767px) {
    .sc_item_title_text_KeepINTouch {
        display: none;
    }
}

.section-spacing {
    padding-top: 100px;
    padding-bottom: 140px;
}

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }



  @keyframes zoomInOut {
    0% {
      transform: scale(1); /* Normal size */
    }
    50% {
      transform: scale(1.5); /* Zoom in */
    }
    100% {
      transform: scale(1); /* Zoom out to normal size */
    }
  }
  
.marginbt{
    margin-top: 1rem !important;
}