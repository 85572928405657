.elementor-52367 .elementor-element.elementor-element-73d21f56:not(.elementor-motion-effects-element-type-background),
.elementor-52367 .elementor-element.elementor-element-73d21f56>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../GalleryAssets//gallery\ \(1\).jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.elementor-52367 .elementor-element.elementor-element-73d21f56 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition-behavior: normal, normal, normal, normal;
    transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;

}

.elementor-widget-wrap>.elementor-element {
    width: 100%;
}

.sc_portfolio_masonry_wrap .sc_portfolio_masonry_item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 30px 30px 0;
    margin-bottom: 0;
    width: 50%;
    border-width: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.mainImageSection {
    display: flex;
    justify-content: end;
    align-items: center;
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-100,
    .elementor-column[data-col="100"] {
        width: 100%;
    }
}

@media (min-width: 767px) {
    .scrollGallery {
        /* height: 500px; */
        overflow: auto;
        scrollbar-width: none;
        height: 773px
    }
}

@media (min-width: 767px) {

    /* .sc_portfolio_masonry_item{
  position: none !important;
    } */
    .gallaryEachImg1 {

        position: absolute !important;
        left: 0% !important;
        top: 0 !important;
    }

    .gallaryEachImg2 {

        position: absolute !important;
        left: 50% !important;
        top: 0 !important;
    }

    .gallaryEachImg3 {

        position: absolute !important;
        left: 0% !important;
        top: 471.016px !important;
    }

    .gallaryEachImg4 {

        position: absolute !important;
        left: 50% !important;
        top: 540.062px !important;
    }

    .gallaryEachImg5 {

        position: absolute !important;
        left: 0% !important;
        top: 1010.078px !important;
    }
}

@media(max-width: 767px) {
    .mainImageSection {
        margin-bottom: 40px !important;
    }
}


/* @media(max-width: 1679px) {
    @media (max-width: 767px) {
        .sc_portfolio .sc_portfolio_masonry_wrap .sc_portfolio_masonry_item {
            padding: 0 ;
            width: 100% !important;
        }
    }
} */

.carousel-image {
    height: 460px !important;
}

.carousel-inner {
    height: 500px !important;
}

@media (max-width: 767px) {  
    .carousel-image {
        height: 160px !important;        
    }
    .carousel-inner {
        height: 215px !important;        
    }   
    .carousel-indicators [data-mdb-target] {
        box-sizing: content-box;
        flex: 0 1 auto !important;
        width: 50% !important;
        height: 3px !important;
        padding: 0;
        margin-right: 0px !important;
        margin-left: 3px !important;
        cursor: pointer;
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 0 !important;
        border-top: 10px solid transparent !important;
        border-bottom: 10px solid transparent !important;
        transition: opacity .6s ease !important;
    } 

    .carousel-control-prev-icon, .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 6em !important;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
    }
}





