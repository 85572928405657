.body_style_fullscreen .page_content_wrap {
    padding: 0;
    position: relative;
}

.contactPadding {
    padding-top: 270px !important;
    padding-bottom: 0 !important;
}

.contact_details_main {
    margin-bottom: "50px" !important;
    text-align: start !important;
}

.sc_iconitem-self {
    /* margin-bottom: 40px !important; */
}

.sc_social_self {
    text-align: start;
}

.input_div_self {
    display: none !important;
}

/* div.wpcf7 .form-style-1 .style-line.icon-name:before {
    content: '\e9ae';
}
div.wpcf7 .form-style-1 .style-line[class*="icon-"]:before {
    color: #A5A5A5;
    display: block;
    font-family: "fontello";
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
} */
div.wpcf7 .form-style-1 .style-line[class*="icon-"]:before {
    color: var(--theme-color-input_text);
    display: block;
    font-family: "fontello";
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
}

.link_text {
    font-family: "DM Sans", sans-serif !important;
}

.link_text a {
    color: var(--theme-color-text) !important;
}

.mapwidth {
    width: 100%;
}

.Radiocss {
    color: var(--theme-color-input_text) !important;
}