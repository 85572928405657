@media (max-width: 768px) {
    .timepunctualself {
        margin: 10px 10px 0 10px !important;
    }

    .timepunctualcss {
        margin: 0px 10px !important;
    }
}

.timepunctualcss {
    margin: 0px 50px;
}