.error404 .page_title {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    font-size: 19.8vw;
}
.post_item_404 .post_content {
    padding: 3em 0 !important;
    overflow: hidden !important;
}

.post_item_404 .page_title {
    float: none;
    width: 100%;
    font-size: 270px;
    color: white !important;
    line-height: 1em;
    margin: 0;
    margin-top: 0 !important;
    letter-spacing: 0;
    text-align: center;
    hyphens: none;
    color: var(--theme-color-text_dark);
    position: relative;
    z-index: 2;
}

@media (max-width: 768px) {
    .page_title {
    font-size: 80px !important;
       
    }
    
    .page_title img {
        max-width: 80px !important;
        height: auto;
        vertical-align: top;
    }
    }

    .page_title img {
        max-width: 100%;
        height: auto;
        vertical-align: top;
    }
.post_item_404 .post_content {
    position: relative;
}

.post_item_404 .post_content {
    padding: 10em 0;
    overflow: hidden;
}
.post_item_404 .page_info {
    float: none;
    width: 100%;
    text-align: center;
    padding: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}
.error404 .page_subtitle {
    font-size: 35px;
}
.post_item_404 .page_subtitle {
    margin: 20px 0 0;
    font-size: 47px;
    line-height: 1.1em;
}
h3 {
    font-family: Marcellus, serif;
    font-size: 2.059em;
    color: white !important;
    font-weight: 400;
    font-style: normal;
    line-height: 1.086em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.94em;
    margin-bottom: 0.62em;
}
.error404 .page_description {
    font-size: 1rem;
}

.post_item_404 .page_description {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 40px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    color: var(--theme-color-text);
}
.page_info p{
    color: white !important;

}
.theme_button {
    color: #FCFCFC !important;
    border-color: #F6B255 !important;
    background-color: #F6B255 !important;
}
.theme_button:hover{
    background-color: white !important;
    color: black !important;
}