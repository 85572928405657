@media (max-width: 767px) {
    .elementor-52477 .elementor-element.elementor-element-af0cc35 .trx_addons_bg_text_char {
        font-size: 40px !important;
        letter-spacing: -1px;
    }
}

@media (max-width: 1279px) {
    .elementor-52477 .elementor-element.elementor-element-af0cc35 .trx_addons_bg_text_char {
        font-size: 80px;
    }
}
.elementor-52477 .elementor-element.elementor-element-af0cc35 .trx_addons_bg_text_char {
    color: #F25A2A;
    font-family: "Marcellus", Sans-serif;
    font-size: 130px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 0.9em;
    letter-spacing: 0px;
}
